import React from "react";

const Privacy = () => {
    return (
        <div className="page">
            <div className="container">
                <div className="page__content">
                    <h1>Privacy Policy</h1>
                    <div>
                        <div>
                            <div>
                                <p>
                                    AnyContent ("We") values and respects your
                                    privacy. Our commitment is to manage
                                    personal data entrusted to us ("Data") with
                                    integrity and security, in compliance with
                                    applicable laws and regulations.
                                </p>
                                <p>
                                    This privacy policy applies to Data
                                    collected via our website{" "}
                                    <a href="https://anycontent.co">
                                        https://anycontent.co
                                    </a>{" "}
                                    ("Site"). By using our Site, you consent to
                                    this privacy policy and the processing of
                                    Data as described below. If you do not
                                    accept this policy, please do not access or
                                    use our Site.
                                </p>
                                <p>
                                    This policy has been effective since October
                                    10, 2023, and may be occasionally updated.
                                    You will be informed of any changes by
                                    email. Continuing to use the Site after such
                                    communications means you accept the changes
                                    made.
                                </p>
                                <p>1. What is personal data? </p>
                                <p>
                                    Personal data refers to any information
                                    related to an identifiable person. This
                                    information can identify you directly (like
                                    your first and last name or email address)
                                    or indirectly through other associated data
                                    (like your computer's IP address, your
                                    credit card number, or your phone number).
                                </p>
                                <p>2. What Data do we collect?</p>
                                <p>
                                    We may collect the following Data when you
                                    use our Site:
                                </p>
                                <ul>
                                    <li>
                                        Data provided by you during registration
                                        and use of our Site, including:
                                        <ul>
                                            <li>Email address</li>
                                            <li>First name </li>
                                            <li>Last name </li>
                                            <li>City </li>
                                            <li>Country </li>
                                        </ul>
                                    </li>

                                    <li>
                                        Additional communications.
                                        <ul>
                                            <li>
                                                You may also choose to share
                                                additional information by
                                                contacting us through our
                                                contact page or other means. The
                                                collection of this data is based
                                                on your voluntary consent.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Data collected automatically while
                                        browsing, including:
                                        <ul>
                                            <li>
                                                Device information: such as the
                                                model, the operating system
                                                version, or the unique device
                                                identifier.
                                            </li>
                                            <li>
                                                Log information: including your
                                                IP address, information about
                                                your device, and details about
                                                your browsing activity.{" "}
                                            </li>
                                            <li>
                                                Cookies and similar
                                                technologies: used to identify
                                                your browser or device and to
                                                collect data related to your
                                                browsing.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <p>
                                    3. Why do we process your personal data at
                                    AnyContent?
                                </p>
                                <ul>
                                    <li>
                                        The information you choose to share with
                                        us by registering and browsing our site
                                        is specifically used to:
                                        <br />
                                        <ul>
                                            <li>
                                                Manage your inquiries when you
                                                contact our team.
                                            </li>
                                            <li>
                                                Keep you informed of our offers
                                                and innovative services.
                                            </li>
                                            <li>
                                                If you wish to withdraw your
                                                consent regarding the use of
                                                your data for these purposes,
                                                you can let us know at any time
                                                by sending an email to:
                                                contact@anycontent.co.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        The data collected automatically through
                                        your use of our site allows us to:
                                        <br />
                                        <ul>
                                            <li>
                                                Optimize your user experience
                                                and the features offered.
                                            </li>
                                            <li>
                                                Better understand your
                                                preferences to offer you
                                                suitable products and services.
                                            </li>
                                            <li>
                                                Innovate and continuously
                                                improve our offer.
                                            </li>
                                            <li>
                                                Ensure site security by
                                                identifying fraud attempts or
                                                malicious activities.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    4. With which entities do we share your
                                    information?
                                </p>
                                <p>
                                    We may share your data with trusted
                                    partners, including:
                                </p>
                                <ul>
                                    <li>
                                        Our service providers, including those
                                        helping us manage our online platform,
                                        such as our hosting service provider or
                                        our partner for financial transactions.
                                    </li>
                                    <li>For our hosting, we trust OVH.</li>
                                    <li>
                                        For payment processing, Stripe takes
                                        care of it, securely retaining your
                                        credit card details without AnyContent
                                        having access.
                                    </li>
                                    <li>
                                        Legal or government authorities, in
                                        cases where we are legally required to
                                        share certain information.
                                    </li>
                                    <li>
                                        Other entities in emergency situations
                                        requiring such disclosure.
                                    </li>
                                </ul>
                                <p>
                                    You can consult their respective policies
                                    for more information on managing your data.
                                    These partners may need to access some of
                                    your data to help us manage the site. We
                                    ensure that they guarantee the
                                    confidentiality of this information and use
                                    it only according to our instructions.
                                </p>
                                <p>
                                    We would like to emphasize that your data is
                                    never sold to third parties and is used
                                    exclusively for the purposes described here.
                                </p>

                                <p>5. Where do we store your data ?</p>
                                <p>
                                    At AnyContent, we take rigorous measures to
                                    ensure that your information is managed
                                    securely and in accordance with this policy,
                                    respecting current regulations.
                                </p>
                                <p>
                                    Your data is stored via OVH, with servers
                                    based in France. However, for some specific
                                    operations, your information may be
                                    processed outside of Switzerland or France.
                                    In such cases, we only proceed with such a
                                    transfer with your explicit agreement.
                                </p>

                                <p>
                                    6. How does AnyContent secure your personal
                                    information?{" "}
                                </p>
                                <p>
                                    At AnyContent, protecting your data is a
                                    priority.
                                </p>
                                <p>
                                    We implement various security technologies
                                    and procedures to protect your personal
                                    information from unauthorized access, use,
                                    modification, or disclosure, by establishing
                                    security measures appropriate to the
                                    sensitivity of the data collected.
                                </p>
                                <p>
                                    We also commit to ensuring that our service
                                    providers adhere to strict security
                                    standards to ensure the confidentiality and
                                    integrity of your data.
                                </p>
                                <p>
                                    However, despite all precautions, the total
                                    security of information exchanged on the
                                    Internet cannot be 100% guaranteed. Thus,
                                    any transmission of information is at your
                                    own risk.
                                </p>

                                <p>
                                    7. For how long does AnyContent keep your
                                    information?
                                </p>
                                <p>
                                    Your data is kept as long as you have an
                                    active account with AnyContent, or as
                                    necessary to provide you with our full
                                    service. After unsubscribing, we may be
                                    required to keep some of your information to
                                    comply with our legal or regulatory
                                    obligations, or to manage our rights (for
                                    example, to assert our rights in court).
                                </p>

                                <p id="eight">8. Cookie Policy </p>
                                <p>
                                    A message informing you of the use of
                                    cookies will appear during your first visit
                                    to our site. A "cookie" will be installed on
                                    your device to remember your acceptance and
                                    avoid presenting you with the message again.
                                </p>
                                <p>
                                    By using our site, you have the choice to
                                    accept or refuse cookies, which help us
                                    collect visit statistics and improve the
                                    user experience. These data, processed by
                                    Google Analytics, are anonymous and used
                                    only for statistical purposes, without being
                                    shared with third parties for commercial
                                    reasons.
                                </p>
                                <p>
                                    If you accept cookies, you can change your
                                    mind at any time by modifying your browser
                                    settings to delete existing cookies or
                                    refuse them in the future. Note that
                                    refusing cookies may affect site
                                    functionality.
                                </p>

                                <p>9. Social media and external links</p>
                                <p>
                                    AnyContent is active on several social
                                    platforms such as Facebook, Twitter,
                                    Pinterest, Instagram, LinkedIn, YouTube,
                                    etc. We recommend that you review the
                                    privacy policies of these platforms to
                                    understand how your data may be used by
                                    them.
                                </p>
                                <p>
                                    Our site may also contain links to other
                                    sites. AnyContent has no control over these
                                    external sites and is not responsible for
                                    their content or privacy practices.
                                </p>

                                <p>
                                    10. What are your rights regarding your
                                    information ?
                                </p>
                                <p>
                                    You have the right to: (i) request access to
                                    your data to check the information we hold
                                    about you; (ii) request the correction of
                                    any inaccurate information; (iii) request
                                    the cessation or limitation of the
                                    processing of your data, although this may
                                    amount to a request for unsubscription and
                                    terminate your access to our services.
                                </p>
                                <p>
                                    For any request or question related to your
                                    data, please contact us at:
                                    contact@anycontent.co
                                </p>
                                <p>
                                    You also have the right to file a complaint
                                    with the competent data protection authority
                                    if you believe that your data has not been
                                    processed properly.
                                </p>

                                <p>11. Contact us </p>
                                <p>
                                    If you have any questions about this privacy
                                    policy, please feel free to reach out to us
                                    at: contact@anycontent.co.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
