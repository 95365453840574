import React from "react";
import { Link } from "react-router-dom";

const Prices = () => {
    return (
        <div id="prices" className="page">
            <div className="container">
                <div className="page__content">
                    <h1>Pricing</h1>
                    <div className="page__content__body">
                        <p>
                            Embrace our customized package designed to transform
                            your creative concepts into the next TikTok
                            sensation with our expert guidelines.
                        </p>
                        <div className="price__container" id="prices-home">
                            <div className="prices__wrapper">
                                <h2 className="text-center">
                                    Craft the perfect content by selecting from
                                    our range of services:
                                </h2>

                                <div className="mt-5">
                                    <ul>
                                        <li>
                                            <span className="rainbow"></span>
                                            Video script (price per word)
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>
                                            Dialogs (price per word)
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>
                                            Video montage guidelines (price per
                                            word)
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>
                                            Visual inspirations (background,
                                            outfits, etc.) (price per
                                            inspiration)
                                        </li>
                                    </ul>
                                </div>
                                <Link
                                    to="/create-order"
                                    className="rainbow-background button btn btn-primary"
                                >
                                    Compose my order now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page__footer">
                    <div className="page__footer__buttons">
                        <div className="page__footer__buttons__wrapper">
                            <div className="page__footer__buttons__button">
                                <img src="../assets/noun-celebrity-2588716-2-cc480ba3ae2b64c250a721260d00e9f6dac626ce1fe1ad8d278aa28ec8b24583.svg" />
                                <span>
                                    More
                                    <br />
                                    engagement
                                </span>
                            </div>
                            <div className="page__footer__buttons__button">
                                <img src="../assets/noun-startup-2724269-2-c3c8d8e73f9d5335b81a64b4ef92931f5d69ee9d935a0a29929396b3d7944cee.svg" />
                                <span>
                                    Optimized
                                    <br />
                                    growth
                                </span>
                            </div>
                            <div className="page__footer__buttons__button">
                                <img src="../assets/noun-anonymous-1233248-2-fe930d50a2d8b00d66485b97ea687c794e2912f9f168346482ae22d6d196bc6e.svg" />
                                <span>
                                    100%
                                    <br />
                                    Anonymous
                                </span>
                            </div>
                            <div className="page__footer__buttons__button">
                                <img src="../assets/noun-vision-2724288-2-dca475e92c9f9d3b411ed1b971989641282d6dacb5b1aaa3dfeae161b2105c63.svg" />
                                <span>
                                    More
                                    <br />
                                    visibility
                                </span>
                            </div>
                            <div className="page__footer__buttons__button">
                                <img src="../assets/noun-angel-boy-3263628-2-f152ecf9f79e553e0eae4579a533e08df3c4934268f2d90c1dfbf7a413457c46.svg" />
                                <span>
                                    100%
                                    <br />
                                    Secure
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>
                            Our support team is at your service 24/7. Feel free
                            to get in touch if you have any inquiries about your
                            order, or if you're curious to learn more about what
                            we offer.
                        </p>
                        <p>
                            And remember to circle back to us when you hit the
                            For You Page! ;)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prices;
