import React from "react";

const Terms = () => {
    return (
        <div className="page">
            <div className="container">
                <div className="page__content">
                    <h1>Terms of use</h1>
                    <div>
                        <p>
                            AnyContent (hereafter referred to as "We/Us/Our") is
                            a French company headquartered in Paris, providing
                            specialized writing services for scripts, dialogues,
                            and video editing guidance tailored for TikTok
                            videos. Pricing is based on the word count in the
                            script and/or dialogues and/or editing guide.
                        </p>
                        <p>
                            The purpose of these Terms and Conditions ("Terms")
                            is to outline how You, the client ("You/Your"), can
                            utilize Our services ("Service").
                        </p>
                        <p>
                            These Terms may be updated and/or supplemented
                            periodically as services evolve. In such cases, You
                            will be notified via email one month before the
                            updates come into effect. Continuing to access or
                            use Our services after being notified of these
                            updates implies Your agreement to the revised Terms.
                        </p>
                        <p>
                            This version of Our Terms is effective as of
                            10/10/2023.
                        </p>
                        <p>1. REGISTRATION</p>
                        <p>
                            <strong>1.1</strong> To access and use Our Service,
                            you must create an account through Our website
                            ("Account").
                        </p>
                        <p>
                            <strong>1.2</strong> During registration, you will
                            be prompted to provide specific information,
                            notably:
                        </p>
                        <ul>
                            <li>Email address</li>
                            <li>Password</li>
                        </ul>
                        <p>
                            As a client, you assure the accuracy and
                            truthfulness of the information provided to Us, and
                            you confirm that you are of legal age and authorized
                            to subscribe to Our Service in your country of
                            residence.
                        </p>
                        <p>
                            Please note that any form of identity theft or
                            misinformation may prompt us to pursue legal action,
                            including criminal charges, against You.
                        </p>
                        <p>2. PAYMENT</p>
                        <p>
                            <strong>2.1</strong> The cost of the Service is as
                            follows: The initial fee is based on the word count
                            for scripts, dialogues, and/or editing guides
                            (detailed pricing available on the website).
                        </p>
                        <p>
                            <strong>2.2</strong> All prices include all
                            applicable taxes.
                        </p>
                        <p>
                            <strong>2.3</strong> Payments are made through Our
                            website in euros by credit card (Visa, MasterCard,
                            American Express). Our site employs a secure online
                            payment system, ensuring encrypted transmission of
                            your banking data. We use Stripe, an external
                            provider, for our payment process and do not store
                            any of your bank or credit card details. For more
                            information, please refer to our privacy policy.
                        </p>
                        <p>3. RIGHT OF WITHDRAWAL</p>
                        <p>
                            <strong>3.1</strong> You have the right to withdraw
                            from the Contract within 14 (fourteen) days
                            following the sending of the Confirmation.
                        </p>
                        <p>
                            <strong>3.2</strong> You have the right to withdraw
                            from the Contract within 14 (fourteen) days
                            following the sending of the Confirmation.
                        </p>
                        <p>
                            <strong>3.3</strong> Upon exercising your right of
                            withdrawal, you will be refunded the amount paid
                            within 5 (five) to 10 (ten) days after we receive
                            your request. The refund will be credited to the
                            payment method used during registration. However, no
                            refund will be issued if you have utilized Our
                            Service within the current month; in this case, your
                            withdrawal will be processed as a cancellation
                            request, effective at the end of the ongoing month.
                        </p>
                        <p>
                            <strong>3.4</strong> The right of withdrawal does
                            not apply if you are residing in Switzerland. Any
                            requests in this context will be processed as a
                            cancellation without any entitlement to a refund.
                        </p>
                        <p>4. SERVICE OPERATION</p>
                        <p>
                            <strong>4.1</strong> After concluding your contract,
                            you should wait 24 hours to receive the content via
                            email.
                        </p>
                        <p>
                            <strong>4.2</strong> You are entitled to request
                            specific content only through another order.
                        </p>
                        <p>
                            <strong>4.3</strong> You are prohibited from:
                        </p>
                        <ul>
                            <li> Sharing your member access; </li>
                            <li>
                                Selling, copying, renting, lending,
                                distributing, transferring, or sub-licensing any
                                content from the Site or Our Service, or using
                                Our Service for professional purposes;
                            </li>
                            <li>
                                Attempting unauthorized access to our systems or
                                those of our subcontractors or engaging in any
                                activity that may disrupt, diminish the quality
                                of, or interfere with the performance or
                                functionality of the Site or Our Service;
                            </li>
                            <li>
                                Using the Site for abusive purposes by knowingly
                                introducing viruses or other malicious programs;
                            </li>
                            <li>Using our Service for spamming;</li>
                            <li>
                                Disparaging our activities or engaging in any
                                behavior that could harm our reputation, either
                                through Our Service or externally (e.g., on
                                social media).
                            </li>
                        </ul>
                        <p>5. TERMINATION</p>
                        <p>
                            <strong>5.1</strong> Termination by the Client. Due
                            to the nature of our service, no cancellation can be
                            done.
                        </p>
                        <p>
                            <strong>5.2</strong> Termination by AnyContent. We
                            also reserve the right to terminate our
                            collaboration, for which we will notify You via
                            email. In case of a breach of these Terms,
                            especially but not limited to Article 4.3, we may
                            suspend Your Account for the duration necessary for
                            any subsequent investigations and, if appropriate,
                            terminate Your Contract with immediate effect. In
                            cases of suspension or termination, You will be
                            notified in advance at the email address provided
                            during registration
                        </p>
                        <p>
                            <strong>5.3</strong> Termination under this article
                            does not entitle you to any refunds, except where
                            such termination is caused by our inability to
                            continue operating Our Service.
                        </p>
                        <p>6. INTELLECTUAL PROPERTY</p>
                        <p>
                            <strong>6.1</strong> All content provided as part of
                            Our Service, including scripts, dialogues, editing
                            guides, videos, texts, graphics, logos, icons,
                            images, audio clips, digital downloads, and data
                            compilations is the property of AnyContent or its
                            content suppliers and is protected by international
                            copyright laws. The compilation of all content on
                            this site is the exclusive property of AnyContent,
                            with copyright authorship for this collection by
                            AnyContent, and protected by international copyright
                            laws.
                        </p>
                        <p>
                            <strong>6.2</strong> Trademarks present on the Site
                            are registered trademarks of AnyContent.
                            AnyContent's trademarks may not be used in
                            connection with any product or service that is not
                            AnyContent's, in any manner that is likely to cause
                            confusion among customers, or in any manner that
                            disparages or discredits AnyContent. All other
                            trademarks not owned by AnyContent or its
                            subsidiaries that appear on this site are the
                            property of their respective owners, who may or may
                            not be affiliated with, connected to, or sponsored
                            by AnyContent or its subsidiaries.
                        </p>
                        <p>
                            <strong>6.3</strong> You may not frame or utilize
                            framing techniques to enclose any trademark, logo,
                            or other proprietary information (including images,
                            text, page layout, or form) of AnyContent and our
                            associates without express written consent. You may
                            not use any meta tags or any other "hidden text"
                            utilizing AnyContent's name or trademarks without
                            the express written consent of AnyContent. Any
                            unauthorized use terminates the permission or
                            license granted by AnyContent.
                        </p>
                        <p>
                            <strong>6.4</strong> You are granted a limited,
                            revocable, and non-exclusive right to create a
                            hyperlink to the home page of AnyContent so long as
                            the link does not portray AnyContent, its
                            associates, or their products or services in a
                            false, misleading, derogatory, or otherwise
                            offensive matter. You may not use any AnyContent
                            logo or other proprietary graphic or trademark as
                            part of the link without express written permission.
                        </p>
                        <p>
                            7. DISCLAIMER OF WARRANTIES AND LIMITATION OF
                            LIABILITY
                        </p>
                        <p>
                            <strong>7.1</strong> Our Site and all information,
                            content, materials, products (including software)
                            and services included on or otherwise made available
                            to you through this site are provided by AnyContent
                            on an "as is" and "as available" basis, unless
                            otherwise specified in writing. AnyContent makes no
                            representations or warranties of any kind, express
                            or implied, as to the operation of this site or the
                            information, content, materials, products (including
                            software) or services included on or otherwise made
                            available to you through this site, unless otherwise
                            specified in writing. You expressly agree that your
                            use of this site is at your sole risk.
                        </p>
                        <p>
                            <strong>7.2</strong> To the full extent permissible
                            by applicable law, AnyContent disclaims all
                            warranties, express or implied, including, but not
                            limited to, implied warranties of merchantability
                            and fitness for a particular purpose. AnyContent
                            does not warrant that this site; information,
                            content, materials, products (including software) or
                            services included on or otherwise made available to
                            you through this site; their servers; or email sent
                            from AnyContent are free of viruses or other harmful
                            components. AnyContent will not be liable for any
                            damages of any kind arising from the use of this
                            site or from any information, content, materials,
                            products (including software) or services included
                            on or otherwise made available to you through this
                            site, including, but not limited to direct,
                            indirect, incidental, punitive, and consequential
                            damages, unless otherwise specified in writing.
                        </p>
                        <p>
                            <strong>7.3</strong> To the full extent permissible
                            by applicable law, AnyContent disclaims all
                            warranties, express or implied, including, but not
                            limited to, implied warranties of merchantability
                            and fitness for a particular purpose. AnyContent
                            does not warrant that this site; information,
                            content, materials, products (including software) or
                            services included on or otherwise made available to
                            you through this site; their servers; or email sent
                            from AnyContent are free of viruses or other harmful
                            components. AnyContent will not be liable for any
                            damages of any kind arising from the use of this
                            site or from any information, content, materials,
                            products (including software) or services included
                            on or otherwise made available to you through this
                            site, including, but not limited to direct,
                            indirect, incidental, punitive, and consequential
                            damages, unless otherwise specified in writing.
                        </p>
                        <p>8. APPLICABLE LAW</p>
                        <p>
                            <strong>8.1</strong> By visiting AnyContent, you
                            agree that the laws of France, without regard to
                            principles of conflict of laws, will govern these
                            Terms and any dispute of any sort that might arise
                            between you and AnyContent or its associates.
                        </p>
                        <p>9. DISPUTES</p>
                        <p>
                            <strong>9.1</strong> Any dispute relating in any way
                            to your visit to AnyContent or to products or
                            services sold or distributed by AnyContent or
                            through AnyContent will be submitted to confidential
                            arbitration in France, except that, to the extent
                            you have in any manner violated or threatened to
                            violate AnyContent's intellectual property rights,
                            AnyContent may seek injunctive or other appropriate
                            relief in any state or federal court in the state of
                            France, and you consent to exclusive jurisdiction
                            and venue in such courts. Arbitration under this
                            agreement shall be conducted under the rules then
                            prevailing of the International Chamber of Commerce.
                            The arbitrator's award shall be binding and may be
                            entered as a judgment in any court of competent
                            jurisdiction. To the fullest extent permitted by
                            applicable law, no arbitration under this Agreement
                            shall be joined to an arbitration involving any
                            other party subject to this Agreement, whether
                            through class arbitration proceedings or otherwise.
                        </p>
                        <p>10. SITE POLICIES, MODIFICATION, AND SEVERABILITY</p>
                        <p>
                            <strong>10.1</strong> Please review our other
                            policies, such as our privacy policy, posted on this
                            site. These policies also govern your visit to
                            AnyContent. We reserve the right to make changes to
                            our site, policies, and these Terms at any time. If
                            any of these conditions shall be deemed invalid,
                            void, or for any reason unenforceable, that
                            condition shall be deemed severable and shall not
                            affect the validity and enforceability of any
                            remaining condition.
                        </p>
                        <p>11. QUESTIONS</p>
                        <p>
                            <strong>11.1</strong> Questions regarding our Terms,
                            Privacy Policy, or other policy-related material can
                            be directed to our support staff by clicking on the
                            "Contact Us" link in the site menu. Or you can email
                            us at: contact@anycontent.co
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
