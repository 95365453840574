import React, { useEffect, useState, useLayoutEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MailModal from "./MailModal";

const Cart = () => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [steps0, setSteps0] = useState(0);
    const [steps1, setSteps1] = useState(0);
    const [steps2, setSteps2] = useState(0);
    const [steps3, setSteps3] = useState(0);

    const offers = [
        {
            name: "Word video script",
            min: 0,
            max: 50,
            bundle: 150,
            unitprice: 2.49,
            step: steps0,
            set: setSteps0,
        },
        {
            name: "Word dialogs",
            min: 0,
            max: 30,
            bundle: 50,
            unitprice: 5.9,
            step: steps1,
            set: setSteps1,
        },
        {
            name: "Word video montage guidelines",
            min: 0,
            max: 18,
            bundle: 100,
            unitprice: 2.39,
            step: steps2,
            set: setSteps2,
        },
        {
            name: "Visual inspirations (background, outfits, etc.)",
            min: 0,
            max: 22,
            bundle: 100,
            unitprice: 1.39,
            step: steps3,
            set: setSteps3,
        },
    ];

    useEffect(() => {
        const total = offers
            .reduce((accumulator, offer) => {
                return accumulator + offer.unitprice * offer.step;
            }, 0)
            .toFixed(2);

        setTotalPrice(total);
    }, [steps0, steps1, steps2, steps3]);

    const handleNextStep = () => {
        new bootstrap.Modal(document.getElementById("mailModal")).show();
    };

    const handleChange = (event, set) => {
        set(event);
    };

    const SliderLabel = ({ offer }) => {
        return (
            <div className="py-2">
                <div className="d-flex justify-content-between">
                    <label htmlFor={offer.name} className="form-label">
                        {offer.step * offer.bundle} {offer.name}
                    </label>
                    <div>{(offer.unitprice * offer.step).toFixed(2) + "€"}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="container cart-container">
            <section className="h-100 h-custom">
                <div className="container ">
                    <MailModal price={totalPrice} />

                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div
                            className="card p-0 "
                            style={{ borderRadius: "15px" }}
                        >
                            <div className="card-body p-0">
                                <div className="row  g-0">
                                    <div>
                                        <div className="d-flex justify-content-center align-items-center  overflow-hidden ">
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center py-5 overflow-hidden  bg-white"
                                                style={{ borderRadius: "15px" }}
                                            >
                                                Compose your order{" "}
                                            </div>
                                        </div>
                                        <div
                                            className="tab-content border-grey bg-light border-top"
                                            id="cartTabContent"
                                        >
                                            <div
                                                className="tab-pane fade show active"
                                                id="custom"
                                                role="tabpanel"
                                                aria-labelledby="custom-tab"
                                            >
                                                {offers.map((offer) => {
                                                    return (
                                                        <div className="slider-container px-5 py-3">
                                                            <SliderLabel
                                                                offer={offer}
                                                            />
                                                            <Slider
                                                                min={offer.min}
                                                                max={offer.max}
                                                                value={
                                                                    offer.step
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleChange(
                                                                        e,
                                                                        offer.set
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}

                                                <hr
                                                    className="mb-4"
                                                    id="end-follo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <button
                type="button"
                className="rainbow-background button btn btn-primary d-flex align-items-center justify-content-center my-2"
                data-mdb-ripple-color="dark"
                id="submit-cart"
                onClick={() => handleNextStep()}
            >
                Continue {totalPrice} €
            </button>
        </div>
    );
};

export default Cart;
