import React from "react";

const Contact = () => {
    return (
        <div className="page" id="contact">
            <div className="container">
                <div className="page__content">
                    <h1>
                        ✌️ <br />
                        Contact us !
                    </h1>

                    <div className="page__content__body">
                        Just shoot us an email at contact@anycontent.co for any
                        question.
                        <br />
                        We’ll be glad to help !
                        <br />
                        <strong>We usually answer within 24h.</strong>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
