import React, { useEffect } from "react";

const Failure = () => {
    const alterClass = () => {
        const headers = document.querySelectorAll("header");
        const footers = document.querySelectorAll("footer");
        const loginModal = document.getElementById("loginModal");
        headers.forEach((header) => {
            header.remove();
        });

        footers.forEach((footer) => {
            footer.remove();
        });

        loginModal && loginModal.remove();
    };

    useEffect(() => {
        alterClass();
    });

    return (
        <div className="container py-5 my-5  d-flex flex-column align-items-center justify-content-center">
            <h2 className="rainbow-text">Your payment failed or you order has been cancelled.</h2>
            <em>Please try again and complete payment.</em>
        </div>
    );
};

export default Failure;
