import React, { useState } from "react";
import { Link } from "react-router-dom";

const MailModal = ({ price }) => {
    const [prospectEmail, setProspectEmail] = useState("");
    const handleChange = (event) => {
        event.target.id === "email"
            ? setProspectEmail(event.target.value)
            : () => {};
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!prospectEmail || !price) return;
        window.location.href = `/payment_redirect?email=${prospectEmail}&price=${price}`;
    };

    return (
        <div
            className="modal fade popin"
            id="mailModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body modal-payment">
                        <form
                            id="payment_request"
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <div
                                id="error"
                                className="alert alert-danger hide"
                            ></div>
                            <span
                                className="invalid-feedback hide"
                                id="card-errors"
                            >
                                <span className="d-block">
                                    <span className="form-error-icon badge badge-danger text-uppercase"></span>
                                    <span
                                        className="form-error-message"
                                        id="card-errors-msg"
                                    ></span>
                                </span>
                            </span>
                            <div className="form-group">
                                <label
                                    htmlFor="subscription_step3_form_email"
                                    className="required"
                                >
                                    Your email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={prospectEmail}
                                    onChange={handleChange}
                                    required="required"
                                    placeholder="Email address"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        required="required"
                                        className="form-check-input"
                                        value="1"
                                    />
                                    <label className="form-check-label required">
                                        I have read, understood and accept the{" "}
                                        <Link to="/terms">
                                            terms and conditions
                                        </Link>
                                    </label>
                                </div>
                            </div>

                            <div className="form-group mb-0 mt-4">
                                <button className="rainbow-background button btn btn-primary d-flex align-items-center justify-content-center">
                                    <div
                                        className="hidden"
                                        data-payment-target="spinner"
                                    >
                                        <svg
                                            className="animate-spin"
                                            style={{
                                                color: "white",
                                                height: "20px",
                                                width: "20px",
                                                marginRight: "10px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    </div>

                                    <img
                                        width="12"
                                        height="17"
                                        data-payment-target="lock"
                                        src="../assets/lock-5681fe84fb83d173c7e1ef29c94f1e9d9ed16d9bf226851ca2a5da92e0e93b46.png"
                                    />
                                    <span className="ml-1">pay {price} €</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailModal;
