import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <main data-controller="payment">
            <div id="homepage">
                <div className="container top-block">
                    <div className="block-left">
                        <div className="price__container" id="prices-home">
                            <div className="prices__wrapper">
                                <h2 className="text-center">
                                    Get professional TikTok video guidelines in
                                    one click
                                </h2>

                                <div className="mt-5">
                                    <ul>
                                        <li>
                                            <span className="rainbow"></span>
                                            💰 Tailor-made pricing
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>✅
                                            No engagement or password required
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>
                                            🕗🔧 Support 24/7
                                        </li>
                                        <li>
                                            <span className="rainbow"></span>
                                            👍 Satisfied or refunded
                                        </li>
                                    </ul>
                                </div>
                                <Link
                                    to="/create-order"
                                    className="rainbow-background button btn btn-primary"
                                >
                                    Order now
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="block-right"
                        style={{ position: "relative" }}
                    >
                        <div className="iphone-x">
                            <i></i>
                            <b></b>

                            <video
                                width="350"
                                autoPlay="autoplay"
                                loop="loop"
                                muted="muted"
                                playsInline={true}
                                className="rounded-3xl mx-auto relative z-10"
                                style={{
                                    filter: "drop-shadow(0px 10px 3px rgba(0,0,0,0.4))",
                                    borderRadius: "40px",
                                }}
                                src="assets/tiktok-video.mp4"
                            >
                                {" "}
                            </video>
                        </div>
                    </div>
                </div>
                <div className="card-wrapper">
                    <div className="container">
                        <div className="top-cards">
                            <div className="card">
                                <div className="card-image">
                                    <img src="assets/noun-celebrity-2588716-2-cc480ba3ae2b64c250a721260d00e9f6dac626ce1fe1ad8d278aa28ec8b24583.svg" />
                                </div>
                                <div className="card-body">
                                    <h2>Enhanced Energy</h2>
                                    <p>
                                        Secure the ultimate script that ensures
                                        your creative vision is powerfully
                                        transformed into a video, making a
                                        profound impact within the TikTok
                                        community.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src="assets/noun-startup-2724269-2-c3c8d8e73f9d5335b81a64b4ef92931f5d69ee9d935a0a29929396b3d7944cee.svg" />
                                </div>
                                <div className="card-body">
                                    <h2>Rapid Execution </h2>
                                    <p>
                                        Receive your meticulously crafted
                                        script, dialogue, or video editing
                                        guidelines within minutes, setting the
                                        stage for immediate recording and the
                                        potential for viral status by evening.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src="assets/noun-anonymous-1233248-2-fe930d50a2d8b00d66485b97ea687c794e2912f9f168346482ae22d6d196bc6e.svg" />
                                </div>
                                <div className="card-body">
                                    <h2>Guaranteed Anonymity </h2>
                                    <p>
                                        Our service pledges complete anonymity;
                                        rest assured, your secret to producing
                                        remarkable content remains undisclosed.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src="assets/noun-vision-2724288-2-dca475e92c9f9d3b411ed1b971989641282d6dacb5b1aaa3dfeae161b2105c63.svg" />
                                </div>
                                <div className="card-body">
                                    <h2>Increased Visibility </h2>
                                    <p>
                                        By elevating the caliber of your
                                        content, your TikTok profile stands to
                                        garner heightened interest, initiating a
                                        momentum akin to a snowball effect: the
                                        larger it grows, the quicker the
                                        expansion.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src="assets/noun-angel-boy-3263628-2-f152ecf9f79e553e0eae4579a533e08df3c4934268f2d90c1dfbf7a413457c46.svg" />
                                </div>
                                <div className="card-body">
                                    <h2>Uncompromised Security</h2>
                                    <p>
                                        Offering a service that is 100%
                                        legitimate and secure, we guarantee no
                                        piece of your personal information or
                                        content ideas will ever be disseminated
                                        online.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-cards">
                            <div className="bottom-cards__wrapper">
                                <h2>How it works ?</h2>
                                <div className="card">
                                    <div className="card-image rainbow-border">
                                        <div className="card-text">1</div>
                                    </div>
                                    <div className="card-body">
                                        <h3>Customize Your Order</h3>
                                        <p>
                                            Tailor the word count in your
                                            guidelines to match your precise
                                            needs, ensuring you receive exactly
                                            what you're looking for.
                                        </p>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-image rainbow-border">
                                        <div className="card-text">2</div>
                                    </div>
                                    <div className="card-body">
                                        <h3>Submit Your Email Address</h3>
                                        <p>
                                            Ensure to provide a valid email as
                                            your guidelines will be delivered
                                            directly to your inbox.
                                        </p>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-image rainbow-border">
                                        <div className="card-text">3</div>
                                    </div>
                                    <div className="card-body">
                                        <h3>
                                            Complete Your Purchase to Receive
                                            Your Guidelines
                                        </h3>
                                        <p>
                                            Upon finalizing your payment, you
                                            can expect to receive your
                                            guidelines in just a few minutes,
                                            streamlining your creative process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-cards__image">
                                <img
                                    width="488"
                                    src="assets/picture-86ffa7aab6570cab7ac3bc6165f55a2eaf25d904b0de2bf9c88a95b24435b8a3.png"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Home;
