import React, { useEffect } from "react";
import { fetchWithHeaders } from "../utils/fetchWithHeaders";

const PaymentRedirect = () => {

  const BACKEND_DOMAIN = import.meta.env.VITE_ORDERS_API_DOMAIN;
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    new bootstrap.Modal(document.getElementById("redirectModal")).show();
    if (params.id && params.slug) {
      validateCart(params.id, params.slug);
    }
    if (params.email && params.price) {
      createCheckoutSession2(params.email, params.price);
    }
  }, []);

  const alterClass = () => {
    const headers = document.querySelectorAll("header");
    const footers = document.querySelectorAll("footer");
    const loginModal = document.getElementById("loginModal");

    headers.forEach((header) => {
      header.remove();
    });

    footers.forEach((footer) => {
      footer.remove();
    });

    loginModal && loginModal.remove();
  };

  useEffect(() => {
    alterClass();
  });

 const validateCart = async (id, slug) => {
    try {
      // const response = await fetchWithHeaders(
      //   `${ORDERS_API_DOMAIN}/cart/${id}/${slug}/check`
      // );
      // const data = await response.json();
      createCheckoutSession(id, slug);
    } catch (error) {
      console.log(error);
    }
  };

  const createCheckoutSession = async (id, slug) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
        slug,
      }),
    };
    try {
      const response = await fetchWithHeaders(
        `${BACKEND_DOMAIN}/cart/checkout`,
        requestOptions
      );
      const data = await response.json();
      if (data.url) {
        setTimeout(() => {
          window.location.href = data.url;
        }, 3000);
      } else {
        // Handle other responses or errors
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCheckoutSession2 = async (email, price) => {
    if (!price || !email) return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        price: price,
        email: email,
      }),
    };
    try {
      const response = await fetchWithHeaders(
        `${BACKEND_DOMAIN}/cart/checkout`,
        `${BACKEND_DOMAIN}/create-checkout-session`,
        requestOptions
      );
      const data = await response.json();
      if (data.url) {
        setTimeout(() => {
          window.location.href = data.url;
        }, 3000);
      } else {
        // Handle other responses or errors
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="modal fade popin"
        id="redirectModal"
        data-controller="login"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-4">
            <div className="modal-body ">
              <h1
                className="text-center my-4 pt-5"
                style={{ fontWeight: "bold" }}
              >
                Thank you !
                <br />
                We are redirecting you to the payment page...
              </h1>
              <div className="text-center my-4">
                If you are not redirected, please click the button below to
                proceed to the checkout page
              </div>
              <div className=" mb-0 mt-4">
                <button className="rainbow-background button btn btn-primary d-flex align-items-center justify-content-center">
                  <div className="hidden" data-payment-target="spinner">
                    <svg
                      className="animate-spin"
                      style={{
                        color: "white",
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                  <span className="m-3">Proceed to checkout </span>{" "}
                  <img src="/assets/right-arrow-svgrepo-com.svg" />
                </button>
              </div>
              <img src="/assets/payment-card1.webp" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentRedirect;
