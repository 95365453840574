import React from "react";

const Faq = () => {
    return (
        <div className="page" id="faq">
            <div className="container ">
                <div className="page__content">
                    <h1>FAQ</h1>

                    <div id="accordion">
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingOne"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-controls="collapseOne"
                            >
                                <h5>
                                    <div>How does our service work ?</div>
                                </h5>
                            </div>

                            <div
                                id="collapseOne"
                                className="collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Just compose your cart with the different
                                    guidelines options we offer to match your
                                    needs. Once your order is completed, you
                                    will receive those guidelines in your
                                    mailbox.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingTwo"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                <h5>
                                    <div>Can I get a free trial ?</div>
                                </h5>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Unfortunately, our service requires top-tier
                                    IA models that have some cost. It is the
                                    price to get the best quality available, but
                                    it prevents us from offering it for free.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingThree"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                <h5>
                                    <div>When will I get my guidelines ?</div>
                                </h5>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    They will be in your mailbox a few minutes
                                    after you completed your order.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingFour"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                <h5>
                                    <div>
                                        I have entered the wrong email, what can
                                        I do ?
                                    </div>
                                </h5>
                            </div>
                            <div
                                id="collapseFour"
                                className="collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Just shoot us an email at
                                    contact@anycontent.co and we’ll take care of
                                    everything :)
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingFive"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                <h5>
                                    <div>Is my data secured ?</div>
                                </h5>
                            </div>
                            <div
                                id="collapseFive"
                                className="collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Yes, we have put a lot of effort into making
                                    sure all the data provided is secured in our
                                    data centers. You can be 100% confident that
                                    it will always be under our surveillance and
                                    that it will be deleted once they are not
                                    needed anymore.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingSix"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSix"
                                aria-expanded="false"
                                aria-controls="collapseSix"
                            >
                                <h5>
                                    <div>
                                        What is the price of the service ?
                                    </div>
                                </h5>
                            </div>
                            <div
                                id="collapseSix"
                                className="collapse"
                                aria-labelledby="headingSix"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Just take a look at the “pricing” page, or
                                    start composing your cart to see the price
                                    of the service.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div
                                className="card-header collapsed"
                                id="headingSeven"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                            >
                                <h5>
                                    <div>Can I cancel an order ?</div>
                                </h5>
                            </div>
                            <div
                                id="collapseSeven"
                                className="collapse"
                                aria-labelledby="headingSeven"
                                data-bs-parent="#accordion"
                            >
                                <div className="card-body">
                                    Due to the nature of the service, once the
                                    order is being processed, it cannot be
                                    canceled. We offer a “satisfied or refunded”
                                    policy though.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
