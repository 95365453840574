import Header from "./components/Header";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./components/Home";
import Prices from "./components/Prices";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Legal from "./components/Legal";
import Terms from "./components/Terms";
import PaymentRedirect from "./components/PaymentRedirect";
import Success from "./components/Success";
import Failure from "./components/Failure";
import Cart from "./components/Cart";
function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/payment_redirect" element={<PaymentRedirect />} />
          <Route path="/payment/success" element={<Success />} />
          <Route path="/payment/canceled" element={<Failure />} />
          <Route path="/create-order" element={<Cart />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
