import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-top">
                    <div>
                        <div className="footer-language">
                            <Link to="/">
                                <img
                                    className="my-3"
                                    src="../assets/anyContentLogo.svg"
                                    alt="anyContentLogo"
                                    style={{ width: "168px" }}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="menu">
                        <ul>
                            <li>
                                <Link to="/prices">Pricing</Link>
                            </li>
                            <li>
                                <Link to="/faq">FAQ</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact us</Link>
                            </li>

                            <li>
                                <Link
                                    data-bs-toggle="modal"
                                    data-bs-target="#loginModal"
                                    style={{ cursor: "pointer" }}
                                >
                                    Log in
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="payment">
                        <ul>
                            <li>
                                <div className="payment__wrapper">
                                    <img src="assets/secure-payment-695b66d42d6669a33a3ad5b9aa167f7640f73cc7a47b5d146c84f02997c34e76.svg" />
                                    <p>Payment 100% secure</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <article>
                    <div className="mentions">
                        <ul>
                            <li>
                                <a>©AnyContent 2023 - All rights reserved</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/privacy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms">Terms of use</Link>
                            </li>
                            <li>
                                <Link to="/legal">Legals</Link>
                            </li>
                        </ul>
                    </div>
                </article>
            </div>
        </footer>
    );
};

export default Footer;
