import React, { useEffect, useState } from "react";
import { fetchWithHeaders } from "../utils/fetchWithHeaders";
const Success = () => {
    const ORDERS_API_DOMAIN = import.meta.env.VITE_ORDERS_API_DOMAIN;

    const [email, setEmail] = useState("");
    const [tracking, setTracking] = useState(false);

    const alterClass = () => {
        const headers = document.querySelectorAll("header");
        const footers = document.querySelectorAll("footer");
        const loginModal = document.getElementById("loginModal");
        headers.forEach((header) => {
            header.remove();
        });

        footers.forEach((footer) => {
            footer.remove();
        });

        loginModal && loginModal.remove();
    };

    const validateOrder = async (id, slug, retry = 0) => {
        try {
            const response = await fetchWithHeaders(
                `${ORDERS_API_DOMAIN}/cart/${id}/${slug}/tracking-url`
            );
            if (response.ok) {
                const data = await response.json();
                if (!data.tracking) return;
                setTracking(data.tracking);
                if (!data.ready) {

                    if (retry > 5) return;
                    setTimeout(() => {
                        validateOrder(id, slug, retry + 1);
                    }, 6000);
                }
                if (data.url) window.location.href = data.url;
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        alterClass();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.email) {
            setEmail(params.email);
        }
        if (params.i && params.s) {
            validateOrder(params.i, params.s);
        }
    }, []);

    return (
        <div className="container py-5 my-5  d-flex flex-column align-items-center justify-content-center">
            <h2 className="rainbow-text"> Payment Success !</h2>
            <em>You received a confirmation email at {email}.</em>
            {tracking ? (
                <div className="loading-container">
                    <p>You will soon be redirected to the tracking page</p>
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Success;
