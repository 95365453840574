import React, { useState } from "react";
import { Link } from "react-router-dom";
import { fetchWithHeaders } from "../utils/fetchWithHeaders";

const Header = () => {
    const BACKEND_DOMAIN = import.meta.env.VITE_ORDERS_API_DOMAIN;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleChange = (event) => {
        event.target.id === "email"
            ? setEmail(event.target.value)
            : event.target.id === "password"
            ? setPassword(event.target.value)
            : () => {};
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let requestOptions;
        switch (event.target.id) {
            case "login-form":
                if (!email.length || !password.length) return;
                requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        user: {
                            email: email,
                            password: password,
                        },
                    }),
                };
                try {
                    const response = await fetchWithHeaders(
                        `${BACKEND_DOMAIN}/login`,
                        requestOptions
                    );
                    if (!response.ok) {
                        const errorDiv = document.getElementById("login-error");
                        errorDiv.innerHTML = "Unauthorized";
                        errorDiv.classList.remove("hide");
                    }
                } catch (error) {}
                break;
            case "forgot-password-form":
                if (!email.length || !password.length) return;
                requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        user: {
                            email: email,
                        },
                    }),
                };
                try {
                    const response = await fetchWithHeaders(
                        `${BACKEND_DOMAIN}/forgot-password`,
                        requestOptions
                    );
                    if (response.ok) {
                        const errorDiv = document.getElementById(
                            "forgot-password-error"
                        );
                        errorDiv.innerHTML =
                            "If your email is registered with us, you will receive a link to reset your password.";
                        errorDiv.classList.remove("hide");
                    }
                } catch (error) {}
                break;
            default:
                break;
        }
    };

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link to="/">
                            <img
                                className="my-3"
                                src="../assets/anyContentLogo.svg"
                                alt="anyContentLogo"
                                style={{ width: "200px", height: "100%" }}
                            />
                        </Link>

                        <div
                            className="navbar-burger"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarToggler"
                            aria-controls="navbarToggler"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <img
                                className="burger-icon"
                                src="../assets/burger-7085881cc59d20d0f7b8490cef14418e2a83350d76581773908353d3fe1dbaaa.svg"
                            />
                            <img
                                className="cross-icon"
                                src="../assets/cross-bb8f729148ed42d0c0418b8b38029e0ffdcd5efab2929c51cadde2462c7ae91d.svg"
                            />
                        </div>

                        <div
                            className="collapse navbar-collapse"
                            id="navbarToggler"
                        >
                            <ul className="navbar-nav ">
                                <li className="nav-item active ">
                                    <Link className="nav-link" to="/">
                                        <div
                                            data-bs-toggle="collapse"
                                            data-bs-target=".navbar-collapse.show"
                                        >
                                            Home
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/prices">
                                        <div
                                            data-bs-toggle="collapse"
                                            data-bs-target=".navbar-collapse.show"
                                        >
                                            Pricing
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/faq">
                                        <div
                                            data-bs-toggle="collapse"
                                            data-bs-target=".navbar-collapse.show"
                                        >
                                            FAQ
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/contact">
                                        <div
                                            data-bs-toggle="collapse"
                                            data-bs-target=".navbar-collapse.show"
                                        >
                                            Contact
                                        </div>
                                    </Link>
                                </li>
                                <li
                                    className="nav-item "
                                    data-bs-toggle="modal"
                                    data-bs-target="#loginModal"
                                >
                                    <Link className="button btn btn-primary">
                                        Login
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <div
                className="modal fade popin"
                id="loginModal"
                data-controller="login"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div
                            className="modal-body"
                            data-login-target="loginForm"
                            id="login"
                        >
                            <form
                                onSubmit={(e) => handleSubmit(e)}
                                id="login-form"
                            >
                                <h1>Login</h1>
                                <div
                                    className="alert alert-danger hide"
                                    id="login-error"
                                >
                                    <span className="translation_missing">
                                        Error
                                    </span>
                                </div>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleChange}
                                    required
                                    className="form-control"
                                    autoFocus
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    id="password"
                                    className="form-control"
                                    required
                                />
                                <p
                                    className="forgot-password"
                                    data-bs-toggle="modal"
                                    data-bs-target="#forgotPassLink"
                                    data-action="click->login#forgotPassword"
                                >
                                    <a
                                        data-action=""
                                        data-modal-if-open=""
                                        style={{ cursor: "pointer" }}
                                    >
                                        Forgot your password ?
                                    </a>
                                </p>
                                <button
                                    className="rainbow-background button btn btn-primary"
                                    type="submit"
                                >
                                    Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade popin"
                id="forgotPassLink"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="forgot-password">
                            <form
                                onSubmit={(e) => handleSubmit(e)}
                                id="forgot-password-form"
                            >
                                <h1>Reset your password</h1>
                                <div
                                    className="alert alert-success hide"
                                    id="forgot-password-error"
                                >
                                    <span className="translation_missing">
                                        Error
                                    </span>
                                </div>
                                <p>
                                    Please enter your email address below. We
                                    will send you instructions for creating a
                                    new password. (If you can't find our email,
                                    check your spam folder.)
                                </p>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleChange}
                                    required
                                    className="form-control"
                                    autoFocus
                                />

                                <button
                                    className="rainbow-background button btn btn-primary"
                                    type="submit"
                                >
                                    Reset
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
