import React from "react";
import { Link } from "react-router-dom";

const Legal = () => {
    return (
        <div className="page">
            <div className="container">
                <div className="page__content">
                    <h1>Legals</h1>
                    <div className="page__content__body">
                        <p>
                            This website uses cookies. Cookies are small text
                            files that are saved on your computer when you visit
                            the site, temporarily or permanently. The purpose of
                            cookies is in particular to analyze the use of the
                            site, with a view to statistical exploitation, as
                            well as continuous improvements. Cookies can be
                            disabled at any time in your web browser settings,
                            partially or completely. By disabling cookies, some
                            features of this site may no longer be available.
                            <br />
                            <Link to="/privacy#eight">Learn more</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Legal;
