export const fetchWithHeaders = (url, options = {}) => {
    const X_API_KEY = import.meta.env.VITE_X_API_KEY;

    const modifiedHeaders = {
        ...options.headers,  
        'x-api-key': X_API_KEY
    };

    const modifiedOptions = {
        ...options,
        headers: modifiedHeaders
    };

    return fetch(url, modifiedOptions);
};